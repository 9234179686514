.App {
  height: 100vh;

  /* grid container settings */
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'main'
    'footer';

  padding: 0px;
  margin: 0px;
}

.Header {
  grid-area: header;
}

.app_main {
  grid-area: main;
}

.Footer {
  grid-area: footer;
}

.player-wrapper {
  width: auto;
  height: auto;
}
.react-player {
  padding-top: 56.25%;
  position: relative;
}

.react-player > div {
  position: absolute;
}
